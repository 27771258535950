/*
<platform-header
  title="商务政策考核"
  :tabData="tabData"
  @tabChange="tabChange"
/>
*/
<template>
  <div :class="{ 'platform-container': showTitle }">
    <div class="title" v-if="showTitle">
      {{ title }}
      <span class="tooltip-name" v-if="tooltipName">{{ tooltipName }}</span>
      <AftTooltip
        v-if="showTooltip && code"
        :code="code"
        style="top: 0px; left: 4px"
      />
    </div>

    <div class="tab-btn-wrap" v-if="showPlatform">
      <a-button
        v-for="(item, index) in platformList"
        :key="'btn' + index"
        round
        :class="[
          'btn-class',
          activeCheck(item.value) ? 'active-btn-class' : '',
        ]"
        :style="defaultBtnStyle"
        @click="tabClick(item.value)"
      >
        <svg-icon
          :icon-class="valueMap[item.value]"
          v-if="!item.isHideIcon"
          class="icon"
        ></svg-icon>
        <span>{{ item.name }}</span>
        <!-- todo -->
        <!-- <div v-if="item.value == 'dongchedi'" class="beta">beta</div> -->
      </a-button>
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
const { mapState, mapActions } = vuex.createNamespacedHelpers("auth");
import { getPrincipalId } from "@/utils/auth";

export default {
  name: "PlatformHeader",
  props: {
    showTitle: {
      type: false,
      default: true,
    },
    tabData: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: " ",
    },
    tooltip: {
      type: String,
      default: "",
    },
    tooltipName: {
      type: String,
      default: "",
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    code: {
      type: String,
      default: " ",
    },
    defaultBtnStyle: {
      type: Object,
      default: () => {
        return {};
      },
    },
    showPlatform: {
      type: Boolean,
      default: true,
    },
    currentTab: {
      type: String,
    },
    isAll: {
      type: Boolean,
      default: false,
    },
    isSetting: {
      type: Boolean,
      default: false,
    },
    isUseName: {
      type: Boolean,
      default: true,
    },
    // 临时变量，是否显示视频号
    hasShiPinHao: {
      type: Boolean,
      default: false,
    },
    // 临时变量，是否显示小红书
    hasXiaoHongShu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentClick: "",
      isFirst: true,
      valueMap: {
        0: "agg",
        1: "douyin",
        2: "kuaishou",
        3: "dongchedi",
        5: "xiaohongshu",
        6: "shipinhao",
      },
    };
  },
  async created() {
    if (
      this.isSetting &&
      (!platformSettingList || platformSettingList.length === 0)
    ) {
      await this.getPlatformSetting(getPrincipalId());
    }
  },
  computed: {
    ...mapState({
      platformSettingList: (state) => state.platformSettingList,
    }),
    platformList() {
      if (this.tabData.length > 0) {
        return this.tabData;
      } else {
        return (
          this.isAll
            ? [
                {
                  name: "全部平台",
                  value: "0",
                },
                ...this.platformSettingList,
              ]
            : this.platformSettingList
        ).filter((item) => {
          return (
            item.value !== 3 &&
            (this.hasXiaoHongShu ? true : item.value !== 5) &&
            (this.hasShiPinHao ? true : item.value !== 6)
          );
        });
      }
    },
    platformListValue() {
      return this.platformList.map((item) => this.valueMap[item.value]);
    },
  },
  watch: {
    currentTab: {
      handler: function (val) {
        if (this.isUseName) {
          this.currentClick = val;
        } else {
          this.currentClick = this.valueMap[val];
        }
        // 如果不存在传入的值
        if (
          this.platformListValue.length > 0 &&
          !this.platformListValue.includes(this.currentClick)
        ) {
          const nameValue = localStorage.platformDefault
            ? this.valueMap[localStorage.platformDefault]
            : this.platformListValue[0];
          const numberValue = localStorage.platformDefault
            ? localStorage.platformDefault
            : this.platformList[0].value;
          this.$emit("tabChange", this.isUseName ? nameValue : numberValue);
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["getPlatformSetting"]),
    activeCheck(value) {
      return (
        this.currentClick === (this.isUseName ? this.valueMap[value] : value)
      );
    },
    tabClick(value) {
      this.$emit("tabChange", this.isUseName ? this.valueMap[value] : value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.platform-container {
  padding: 30px 20px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    font-size: 18px;
    // @include font_color("font_color20");
    color: #ffffff;
  }
  .tooltip-name {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.45);
    margin-left: 16px;
  }
}
.btn-class.active-btn-class {
  // @include background_color("background_color13");
  background: #ffca5c;
  // @include font_color("font_color25");
  color: #1c1e27;
  font-weight: bold;
}

.btn-class.active-btn-class.first {
  animation: btnPlay 2s;
  -webkit-animation: btnPlay 2s;
}
.btn-class {
  cursor: pointer;
  // @include font_color("font_color22");
  color: #9ca8ba;
  min-width: 114px;
  width: 114px;
  height: 40px;
  border: none;
  line-height: 30px;
  padding: 5px 10px;
  border-radius: 0px !important;
  // @include background_color("background_color2");
  background: #2b2f3b;
}
.btn-class:first-child {
  border-radius: 8px 0 0 8px !important;
}
.btn-class:last-child {
  border-radius: 0 8px 8px 0 !important;
  border-right: 1px solid rgba(0, 0, 0, 0);
}
@keyframes btnPlay {
  0% {
    background: #2b2f3b;
    color: #9ca8ba;
  }
  25% {
    background: #ffca5c;
    color: #1c1e27;
  }
  50% {
    background: #2b2f3b;
    color: #9ca8ba;
  }
  100% {
    background: #ffca5c;
    color: #1c1e27;
  }
}
@-webkit-keyframes btnPlay {
  0% {
    background: #2b2f3b;
  }
  25% {
    background: #ffca5c;
  }
  50% {
    background: #2b2f3b;
  }
  100% {
    background: #ffca5c;
  }
}

@-o-keyframes btnPlay {
  0% {
    background: #2b2f3b;
  }
  25% {
    background: #ffca5c;
  }
  50% {
    background: #2b2f3b;
  }
  100% {
    background: #ffca5c;
  }
}
@-moz-keyframes btnPlay {
  0% {
    background: #2b2f3b;
  }
  25% {
    background: #ffca5c;
  }
  50% {
    background: #2b2f3b;
  }
  100% {
    background: #ffca5c;
  }
}

.tab-btn-wrap {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .btn-class {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px !important;
    & + .btn-class {
      margin-left: 10px;
    }
    .icon {
      margin-right: 4px;
      display: inline-block;
      font-size: 24px;
      // @include font_color("font_color23");
      color: #8890a6;
    }
    &.active-btn-class {
      .icon {
        // @include font_color("font_color25");
        color: #1c1e27;
      }
    }
  }
}
.beta {
  width: 36px;
  height: 14px;
  border-radius: 7px;
  background: #ff4160;
  color: #fff;
  font-size: 10px;
  text-align: center;
  line-height: 14px;
  position: absolute;
  top: -6px;
  right: -12px;
}
</style>
