<template>
  <div class="login-container">
    <div class="login-wrapper">
      <div class="login-pic"></div>
      <div class="login-form">
        <a-form-model
          class="login-form-box"
          ref="loginForm"
          :model="loginForm"
          :rules="loginRules"
          auto-complete="on"
          label-position="left"
        >
          <template>
            <div class="title-container">
              <div>
                <img
                  class="login-logo"
                  src="@/assets/logo-lingxi/logo-b.png"
                  alt=""
                />
                <h3 class="title">欢迎登录</h3>
              </div>
            </div>
            <a-form-model-item prop="username">
              <a-input
                ref="username"
                v-model="loginForm.username"
                placeholder="请输入账号"
                name="username"
                type="text"
                auto-complete="on"
              >
                <span class="svg-container" slot="prefix">
                  <img
                    class="img-icon"
                    src="@/assets/logo-lingxi/user.png"
                    alt=""
                  />
                </span>
              </a-input>
            </a-form-model-item>

            <a-form-model-item prop="password">
              <a-input
                ref="password"
                v-model="loginForm.password"
                type="password"
                placeholder="请输入密码"
                name="password"
                @keyup.enter.native="handleLogin"
              >
                <span class="svg-container" slot="prefix">
                  <img
                    class="img-icon"
                    src="@/assets/logo-lingxi/password.png"
                    alt=""
                  />
                </span>
              </a-input>
            </a-form-model-item>

            <a-button
              :loading="loading"
              type="primary"
              style="
                width: 100%;
                height: 44px;
                font-size: 16px;
                color: #fff;
                border: none;
                background: #2558f0;
              "
              @click="handleLogin"
              >立即登录
            </a-button>
          </template>
        </a-form-model>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login-lingxi",
  props: {
    finishSubmit: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    finishSubmit(val) {
      if (val) {
        this.loading = false;
        this.$emit("resetFinishSubmit");
      }
    },
  },
  data() {
    const validUsername = (str) => {
      if (typeof str === "string") {
        if (str.trim()) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    };
    const validateUsername = (rule, value, callback) => {
      if (!validUsername(value)) {
        callback(new Error("请输入正确的账号"));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("密码不能少于6位"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        username: undefined,
        password: undefined,
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", validator: validateUsername },
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePassword },
        ],
      },
      loading: false,
    };
  },
  methods: {
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$emit("submit", this.loginForm);
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$bg: #283443;
$light_gray: #000;
$cursor: #000;

.link-btn {
  color: var(--c-primary-color);
  font-size: 14px;
  &:hover {
    opacity: 0.8;
  }
}

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 10px 5px 10px 12px;
      color: $light_gray;
      height: 47px;
      caret-color: $cursor;
      font-size: 14px;
      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px #ecf4fa inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
      &::placeholder {
        color: #999999;
      }
    }
  }

  .a-form-model-item {
    border-bottom: 1px solid rgb(225, 225, 225);
    // background: rgba(255, 255, 255, 0.2);
    color: #454545;
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #c5c5c5;
$light_gray: #000;

.login-container {
  min-height: 100%;
  height: 100vh;
  width: 100%;
  background: #e4ecf6;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  .login-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    background: #fff url("../../assets/logo-lingxi/logo-pic.jpg") no-repeat left
      center;
    background-size: cover;
  }

  .login-pic {
    width: 100%;
    flex: 1;
  }

  .login-form {
    width: 36%;
    min-width: 350px;
    height: 100%;
    flex-shrink: 0;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login-form-box {
    width: 80%;
    transform: translateY(-20%);
  }

  .svg-container {
    padding: 6px 5px 0 0;
    width: 15px;
    display: inline-block;
    img {
      position: relative;
      left: -12px;
      top: -4px;
      width: 17px;
    }
  }

  /deep/ .ant-input-affix-wrapper {
    background-color: #fff;
    border: none;
    border-bottom: 1px solid #c5c5c5;
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      font-size: 14px;
      background-color: #fff !important;
      border: none;
      color: #333333 !important;
      box-shadow: 0 0 0 30px white inset !important;
      transition: background-color 5000s ease-in-out 0s !important;
    }
  }

  /deep/ .ant-input {
    border: none;
    border-radius: 0;
    height: 48px;
    transition: none;
  }

  /deep/ .ant-input {
    &:hover {
      border: none;
      box-shadow: none;
    }
    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  /deep/ .has-error .ant-input-affix-wrapper .ant-input,
  .has-error .ant-input-affix-wrapper .ant-input:hover {
    background-color: #fff;
    border: none;
    border-bottom: 1px solid #f5222f;
  }

  .title-container {
    position: relative;
    margin-bottom: 30px;
    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 0 auto;
      font-weight: bold;
    }
    p {
      text-align: center;
      color: $light_gray;
    }
    .login-logo {
      width: 170px;
      margin-bottom: 50px;
    }
  }
}
</style>
